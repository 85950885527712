$(document).on 'click', '.user-invitation-password-btn', (e) ->
  e.preventDefault()
  $('.error_color').addClass('hidden')

  password = $('#invited_user_password').val()
  confirmation_password = $('#invited_user_confirmation_password').val()

  count = 0
  $('#invited_user_password_setup').find('.input_validation').each ->
    if $(this).val().length < 1
      count++
      $(this).parent().parent().find('.input_field_validation').removeClass('hidden')
  if count == 0
    if $('#invited_user_password').val().length < 6
      $('#invited_user_validation_password').removeClass('hidden')
      false
    else if $('#invited_user_confirmation_password').val().length < 6
      $('#invited_user_validation_confirmation_password').removeClass('hidden')
      false
    else if password == confirmation_password
      $("#invited_user_password_setup").submit()
    else
      $('.invited_user_password_match_validation').removeClass('hidden')


$(document).on 'click', '.user-invitation-email-btn', (e) ->
  e.preventDefault()
  $('.error_color').addClass('hidden')
  email = $('#invited_member_email').val()
  if email == ''|| email == null
    $('#new-member-invitation_id').find('.email_present_validation').removeClass('hidden')
  else
    current_request = $.ajax '/company_users/validate_invited_member_email',
      type: 'POST'
      data:{
        invitation_email: email
      }
      success:(response) ->
        if response.success?
          $('.error_color').addClass('hidden')
          $("#new-member-invitation_id").submit()
        else
          $('#validation_email').text(response.message)
          $('#validation_email').removeClass('hidden')