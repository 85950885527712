$(document).on 'click', '[data-behaviour="broadcast-form-button"]', () ->
  target_form = $(this).data('target-form')

  $.ajax window.location.pathname,
    type: 'GET',
    data: {
      target_form: target_form
    }
    success: (data, jqxhr, textStatus) ->
      $.when($('#step-form').html data).then ->
        $ -> $('.select2').select2()
      window.unlayer_init()
      window.load_broadcast_unlayer()
      window.show_hide_unlayer_editor()


$(document).on 'turbolinks:load', () ->
  $.when($('#broadcast_segment_ids')).then ->
    $('#broadcast_segment_ids').select2
      placeholder: 'Select Segments*'
      closeOnSelect: false