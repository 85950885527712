$(document).on 'click', '[data-behaviour="ses-form-button"]', () ->
  action = $(this).data('target-action')
  ses_id = $(this).data('ses-id')
  if action == 'Edit'
    url = 'amazon_ses_configurations/' + ses_id + '/edit'
  else
    url = 'amazon_ses_configurations/new'
  $.ajax url,
    type: 'GET'
    success: (data, jqxhr, textStatus) ->
      $.when($('#ses-form').html data).then ->
        $('.modal-title').text(action + ' configuration')
        $ -> $('.select2').select2()
