$(document).on "turbolinks:load", () ->
  $(document).on 'click', '.modal-login-btn', (e) ->
    e.preventDefault()
    $('.error_color').addClass('hidden')
    count = 0
    $('.user_login').find('.input_validation').each ->
      if $(this).val() == ''
        count++
        $(this).parent().parent().find('.error_color').removeClass('hidden')
    if count == 0
      $(".user_login").submit()

  if window.location.href.indexOf('sign_in') > -1
    $('#authModal').modal('show')

$(document).on 'click', '.signup-link', () ->
  $('#authModal').modal('hide');

$(document).on 'click', '.modal-signup-btn', (e) ->
    e.preventDefault()
    $('.error_color').addClass('hidden')
    count = 0
    $('#new_user_sign_up').find('.input_validation').each ->
      if $(this).val() == '' || $(this).val() == null
        count++
        $(this).parent().parent().find('.vacancy_validation').removeClass('hidden')
    if count == 0
      if $('#new_user_password').val().length < 6
        count++
        $('#validation_password').removeClass('hidden')
        false
    if count == 0
        email= $('#new_user_email').val()
        current_request = $.ajax '/landing_page/validate_signup',
          type: 'POST'
          data:{
            sign_up_email: email
          }
          success:(response) ->
            if response.success?
              $('.error_color').addClass('hidden')
              $("#new_user_sign_up").submit()
            else 
              $('#validation_email').text(response.message)
              $('#validation_email').removeClass('hidden')
