$(document).on "turbolinks:load", () ->

  if location.pathname.includes('/templates/')
    window.unlayer_init()  
   
    if location.pathname.includes('edit')
      
      template_id = location.pathname.split('/')[2]
      $.ajax "/templates/#{template_id}/fetch_design",
        type: 'GET'
        success: (data, jqxhr, textStatus) ->
          if data.template_type == 'unlayer'
            if data.data_json == "" # for legacy
              unlayer_reset()
              window.unlayer.loadDesign({
                  html: data.data_html,
                  classic: true
                });
            else
              unlayer.loadDesign(JSON.parse(data.data_json))
            show_unlayer_editor()
        error: ( xhr, status, error) ->
          console.log(error)
    

  if location.pathname.includes('/broadcasts/')
   if location.pathname.includes('edit')
      window.unlayer_init()
      window.load_broadcast_unlayer()
  
  window.show_hide_unlayer_editor()

    


$(document).on 'click', "[data-behaviour='html-toggle-btn']", (e) ->
    hide_unlayer_editor()


$(document).on 'click', "[data-behaviour='unlayer-toggle-btn']", (e) ->
    show_unlayer_editor()

window.unlayer_init = () -> 
  if $("#editor-container").length != 0
    $.ajax "/templates/unlayer_details",
      type: 'GET'
      dataType: 'json'
      success: (data, jqxhr, textStatus) -> 
        unlayer.init({
          id: 'editor-container'
          displayMode: 'email',
          projectId: data.project_id,
          user: {
            id: data.user_id
            signature: data.hmac_secret
          }
        })
      error: ( xhr, status, error) ->
        console.log(error)

window.load_broadcast_unlayer = () ->
  broadcast_id = location.pathname.split('/')[2]
  $.ajax "/broadcasts/#{broadcast_id}/get_template_design",
    type: 'GET'
    success: (data, jqxhr, textStatus) -> 
      if data
        if data.template_type == 'unlayer'
          if data.data_json == "" # for legacy
              unlayer_reset()
              window.unlayer.loadDesign({
                  html: data.data_html,
                  classic: true
                });
          else
            window.unlayer.loadDesign(JSON.parse(data.data_json))
      if data?.data_html
        $('.send-test-email-btn').removeClass('disabled').tooltip('disable')
    error: ( xhr, status, error) ->
      console.log(error)

$(document).on 'click', "[data-behaviour='template-card-image']", (event) ->
  template_id = $(this).data('template-id')
  template_type = $(this).data('template-type')
  # For getting the template id in broadcast compose email. This will be used for sending test email
  if $('.send-test-email-btn')
    $('.send-test-email-btn').data("template-id", "#{template_id}")

  source = $(this).data('source')
  if source == 'featured'
    url =  "/featured_templates/#{template_id}/fetch_design"
  else 
    url =  "/templates/#{template_id}/fetch_design"
  $.ajax url,
  type: 'GET'
  success: (data, jqxhr, textStatus) ->
    if template_type == 'unlayer'
      if data.data_json == "" # for legacy
          unlayer_reset()
          window.unlayer.loadDesign({
              html: data.data_html,
              classic: true
            });
      else
        window.unlayer.loadDesign(JSON.parse(data.data_json))
      show_unlayer_editor()
    else
      $('.template-html-field').val(data.data)
      hide_unlayer_editor()
    $('#load-template-modal').modal('hide');
  error: ( xhr, status, error) ->
    console.log(error)

hide_unlayer_editor = () -> 
  $('#editor-container').addClass('hidden')
  $('.rich-text').removeClass('hidden')
  $('.unlayer-template-btn').removeClass('active')
  $('.html-template-btn').addClass('active')
  $('.template-submit').data("behaviour","html-save-btn")
  $('.upload-unlayer-html').addClass('hidden')

show_unlayer_editor = () ->
  $('#editor-container').removeClass('hidden')
  $('.rich-text').addClass('hidden')
  $('.unlayer-template-btn').addClass('active')
  $('.html-template-btn').removeClass('active')
  $('.template-submit').data("behaviour","unlayer-save-btn")
  $('.upload-unlayer-html').removeClass('hidden')

show_hide_test_email_btn = (template_type) -> 
  if template_type == 'html'
    if $('.template-html-field').val() != 0
      $('.send-test-email-btn').removeClass('disabled').tooltip('disable')

# Lagacy unlayer for uploading html
$(document).on 'change', ".upload-unlayer-html", (event) ->
  if event.target.files[0].type != "text/html"
    alert("File selected is not an HTML file")
    return
  
  reader = new FileReader();
  
  reader.onload = (event) ->
    if event.target.result.length == 0
      alert("File has no content")
      return
    
    unlayer_reset()
    unlayer.loadDesign({
      html: event.target.result,
      classic: true
    });

  reader.readAsText(event.target.files[0])

window.show_hide_unlayer_editor = () -> 
  if $('.compose-email-form') || $('.template-form') 
    
    template_type = $('#template-type').data('template-type')
    
    if $('.compose-email-form')
      show_hide_test_email_btn(template_type)

    if template_type == 'html'
      hide_unlayer_editor()
    else if template_type == 'unlayer' || template_type == ''
      show_unlayer_editor()

unlayer_reset = () -> 
  unlayer.loadBlank({
        backgroundColor: '#e7e7e7'
  });