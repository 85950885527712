$(document).on 'click', '[data-behaviour="company-form-button"]', () ->
  action = $(this).attr("data-target-action")
  company_id = $(this).data("company-id")
  if action == 'Edit'
    url =  '/companies/' + company_id + '/edit'
  else
    url = '/companies/new'
  $.ajax url,
    type: 'GET'
    success: (data, jqxhr, textStatus) ->
      $.when($("#company-form").html data).then ->
        $(".modal-title").text("#{action} company")
        $ -> $('.select2').select2()

