
$(document).on 'click', '.form_submit', (e) ->
  e.preventDefault()
  submit = []
  form_class = $(this).data('class-name')
  form_type = $(this).data('form-type')
  button_element = $(this)
  button_behaviour = $(this).data('behaviour')
  button_name = $(this).data("button-name")
  next_page = $(this).data('next-page')
  is_segments_path = window.location.pathname.indexOf('segments') > -1

  ##--- Custom validation for HTML Template (Merge tag)---- ##
  if button_behaviour == 'html-save-btn'
    # if $('.template-html-field').val().toLowerCase().includes('{{unsubscribe_url}}') == false
    #   alert("Please add the 'unsubscribe_url' tag")
    #   return false
    if $('.template-html-field').val() == ''
      alert("Please add some content.")
      return false

    # Used to see if the unlayer has any data or not
    await check_unlayer_content()

  if button_behaviour == 'unlayer-save-btn'
    unlayer_save_design($(this))
  else
    $('.' + form_class + ' .input_validation').each ->
      class_name = $(this).data('class-name')
      $('.' + class_name + '.error_color').addClass('hidden')
      $(this).parent().find('.text-muted').css('display', 'block')
      if is_validation_required(class_name, this)
        regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/; # Validate Email
        email = $(this).val()
        if regex.test(email)
          $('.valid_' + class_name).addClass('hidden')
        else if $(this).val().length != 0
          $('.' + class_name).addClass('hidden')
          $('.valid_' + class_name).removeClass('hidden')
          $(this).parent().find('.text-muted').css('display', 'none')
          submit.push(true)
        else
          $('.' + class_name).removeClass('hidden')
          $('.valid_' + class_name).addClass('hidden')
          $(this).parent().find('.text-muted').css('display', 'none')
          submit.push(true)
    if $('#broadcast_reply_emails').length != 0 && $('#broadcast_reply_emails').val().length > 0
      regex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/ #Validate comma separated emails
      unless regex.test($('#broadcast_reply_emails').val())
        $('.valid_reply_emails').removeClass('hidden')
        submit.push(true)
      else
        $('.valid_reply_emails').addClass('hidden')
    else if form_class == 'unsubscribe_form'
      unless $('.reason_id').filter(':checked').length > 0 || $('#Other').filter(':checked').length > 0
        alert 'Please select at least one checkbox'
        submit.push(true)
    else
      $('.valid_reply_emails').addClass('hidden')

    button_element.button('loading')
    setTimeout (->
      button_element.button 'reset'
      return
    ), 3000
    if submit.length == 0
      button_element.attr('disabled','disabled')
      path_name = $(this).data('path-name')
      action = $(this).data('action')
      data = $('.' + form_class).serializeArray()
      # param has to be sent for controller to redirect to the next step
      if next_page
        data.push({name: 'target_form', value: next_page})
      if action == 'new'
        type = 'POST'
      else
        type = 'PATCH'
      if is_js_submission(form_class, is_segments_path)
        data_type = 'script'
      else
        data_type = 'json'
      if path_name
        url = '/' + path_name
        $.ajax url,
          type: type
          data: data
          dataType: data_type
          success: (data, jqxhr, textStatus) ->
              if form_type == 'template'
                button_element.attr('disabled', 'disabled').text('Saving...')
                setTimeout ( ->
                  window.location.replace(location.origin + '/templates')
                ), 2500
              else if form_type == 'broadcast_template'
                alert('Design saved successfully')
                button_element.prop('disabled', false)
                $('.send-test-email-btn').removeClass('disabled').tooltip('disable')
                # ICO save & next the page will reload and go to step 3
                if next_page
                  location.reload()
              else if window.location.pathname.includes('/broadcasts/new')
                window.location.replace(location.origin + '/broadcasts/' + data.id + '/edit')
              else if form_class == 'contact_form' && is_segments_path
                # Will be handled via contacts/create.js.erb
              else
                location.reload()
          error: (json, jqxhr, textStatus, errorThrown) ->
            $('.error_color').addClass('hidden')
            button_text = button_element.data('button-name')
            button_element.prop('disabled', false).text(button_text)
            error = JSON.parse(json.responseText)
            $.each error, (key, value) ->
              if $('.error_color.' + key)[0]
                $('.error_color.' + key).removeClass('hidden').text(value.join(', '))
      else
        $('.' + form_class).submit()

unlayer_save_design = (context) ->
  event.preventDefault()
  button_element = context
  form_class = context.data('class-name')
  name = $('.template-name-field').val()
  note = $('.template-note-field').val()
  alias_name = $('.template-alias_name-field').val()
  html_text = $('.template-html-field').val()
  path_name = context.data('path-name')
  action = context.data('action')
  next_page = context.data('next-page')

  if html_text.length > 0
    confirmation = confirm("All your HTML design will be deleted!")
    if confirmation == false
      return false

  if form_class == 'template_form'
    if name == ''
      $('.name_div').removeClass('hidden')
    else
      $('.name_div').addClass('hidden')

    if alias_name == ''
      $('.alias_name_div').removeClass('hidden')
    else
      $('.alias_name_div').addClass('hidden')
      current_request = $.ajax '/templates/validate_alias_name',
        type: 'POST'
        data:{
          template_alias_name: alias_name
        }
        success:(response) ->
          if response.success?
            $('.error_color').addClass('hidden')
          else
            $('#validation_alias_name').text(response.message)
            $('#validation_alias_name').removeClass('hidden')

  unlayer.exportHtml( (data) ->
    json = JSON.stringify(data.design)
    if json.includes 'unsubscribe_url'
      html = data.html
      $('.template-html-field').val('')
      payload = $('.' + form_class).serializeArray()
    
      payload.push({name: 'design_json', value: json})
      payload.push({name: 'design_html', value: html})
      if next_page
        payload.push({name: 'target_form', value: next_page})

      if action == 'new'
        type = 'POST'
      else
        type = 'PATCH'
      if path_name
        url = '/' + path_name
        $.ajax url,
        type: type
        dataType: 'text'
        data: payload
        success: (data, jqxhr, textStatus) ->
          if form_class == 'template_form'
            button_element.attr('disabled', 'disabled').text('Saving...')
            setTimeout ( ->
              window.location.replace(location.origin + '/templates')
            ), 5000
          else if form_class == 'broadcast_form'
            alert('Design saved successfully')
            $('.send-test-email-btn').removeClass('disabled').tooltip('disable')
            if next_page
              location.reload()
    else
      alert('Please add unsubscribe_url to your design')

    # In case of a legacy template exportPlainText won't work and through an error

    # unlayer.exportPlainText( (data) ->
    #     # text = data.text;

    #     # if text.toLowerCase().replace(/ /g,'').includes('{{unsubscribe_url}}')
    #     # unless text == ""
    #     if action == 'new'
    #       type = 'POST'
    #     else
    #       type = 'PATCH'
    #     if path_name
    #       url = '/' + path_name
    #       $.ajax url,
    #       type: type
    #       dataType: 'text'
    #       data: payload
    #       success: (data, jqxhr, textStatus) ->
    #         if form_class == 'template_form'
    #           button_element.attr('disabled', 'disabled').text('Saving...')
    #           setTimeout ( ->
    #             window.location.replace(location.origin + '/templates')
    #           ), 5000
    #         else if form_class == 'broadcast_form'
    #           alert('Design saved successfully')
    #           $('.send-test-email-btn').removeClass('hidden')
    #           if next_page
    #             location.reload()
                  
    #     # else
    #     #   alert("Please add some content.")
    #     # else
    #     #   alert("Please add the 'unsubscribe_url' merge tag")

    #     )
    )


check_unlayer_content = () ->
  return new Promise ((resolve, reject) -> 
  # In case of a legacy template exportPlainText won't work and through an error

    # unlayer.exportPlainText( (data) ->
    #   text = data.text 
    #   if text
    #     unless confirm("All your UNLAYER design will be deleted!")
    #       return false
    #     else
    #       unlayer.loadBlank({
    #          backgroundColor: '#e7e7e7'
    #         });
    #   resolve()
    # )

    # for now loading blank directly 
    unlayer.loadBlank({
        backgroundColor: '#e7e7e7'
    });
    resolve()
  )

is_validation_required = (class_name, element) ->
  if $(element).val() == '' || (class_name == "email_div" && $(element).val().length != 0) || $(element).val().length == 0
    return true
  else
    return false

is_js_submission = (form_class, is_segments_path) ->
  if (form_class == 'contact_form' && is_segments_path) || form_class == 'test_email_form'
    return true
  else
    return false


$(document).on 'change', '#Other', ()->
  if $(this).is(':checked')
    $('#other_reason_id').removeClass('hidden')
    $('#other_reason_id').addClass('input_validation')
  else
    $('#other_reason_id').removeClass('input_validation')
    $('#other_reason_id').addClass('hidden')
    $('.response_div').addClass('hidden')
  return

$(document).on 'click', '.form_submit', (e)->
  if $('#other_reason_id') && $('#other_reason_id').hasClass('input_validation')
    if $('#other_reason_id').val() == ""
      e.preventDefault()
      $('.response_div').removeClass('hidden')




