// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("spotlight_search")
require("daterangepicker")
require('bootstrap-datepicker')
require("trix")
require("@rails/actiontext")
require("chartkick")
require("chart.js")
// require("@fortawesome/fontawesome-free")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jQuery from 'jquery';
window.$ = jQuery
window.jQuery = jQuery
import 'select2';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import '../application/javascripts/spotlight_search.js';
// import "@fortawesome/fontawesome-free/css/all"


function importAll (r) {
  r.keys().forEach(r);
}
importAll(require.context('../application/coffee_scripts', true, /\.coffee$/));

