$(document).on 'click', '[data-behaviour="contact-form-button"]', () ->
  action = $(this).attr("data-target-action")
  contact_id = $(this).data("contact-id")
  console.log(action)
  if action == 'Edit'
    url =  '/contacts/' + contact_id + '/edit'
  else
    url = '/contacts/new'
  $.ajax url,
    type: 'GET'
    success: (data, jqxhr, textStatus) ->
      $.when($("#contact-form").html data).then ->
        $(".modal-title").text("#{action} contact")
        $ -> $('.select2').select2() 
        $ -> $('.datepicker').datepicker({
              format: 'dd-mm-yyyy',
              todayHighlight: true,
              autoclose: true,
            }).attr('readonly', true)

$(document).on "turbolinks:load", () ->    
$(document).on 'click', '[data-behaviour="export-link"]', () ->
  model = $(this).data('resource-name')
  $.ajax "/#{model}s/download_excel_file",
    type: 'POST'
    success: (data, jqxhr, textStatus) ->
      window.location = data.url
