var current_request, get_paginated_list;
current_request = null;

get_override_paginated_list = function(page, thisObj) {
  var filter_params, query_string, sort_column, sort_direction, success_replace_class, url;
  url = $('.filters').data('filter-url');
  success_replace_class = $('.filters').data('replacement-class');
  if (localStorage.getItem('selected_count') == null)
    selected_count = "0"
  else
    selected_count = localStorage.getItem('selected_count')
  if (typeof page === void 0) {
    page = 1;
  }
  if (thisObj.data('behaviour') === "sort") {
    sort_column = thisObj.data('sort-column');
    sort_direction = thisObj.data('sort-direction');
  } else {
    sort_column = $('[data-behaviour="current-page"]').data('sort-column');
    sort_direction = $('[data-behaviour="current-page"]').data('sort-direction');
  }
  filter_params = {};
  $('[data-behaviour="filter"]').each(function(index) {
    filter_params[$(this).data('scope')] = $(this).val();
  });
  query_string = {
    filters: filter_params,
    page: page,
    sort: {
      sort_column: sort_column,
      sort_direction: sort_direction
    }
  };
  return current_request = $.ajax(url, {
    type: 'GET',
    data: query_string,
    beforeSend: function() {
      if (current_request !== null) {
        current_request.abort();
      }
    },
    success: function(data) {
      var q;
      q = jQuery.param(query_string);
      window.history.pushState("", "", url + "?" + q);
      $('.' + success_replace_class).html(data);
      $('.selected-items-count').text(selected_count + ' selected')
      if (localStorage.getItem("delete_ids") != "" && localStorage.getItem("delete_ids") != null) {
        selected_ids = JSON.parse(localStorage.getItem("delete_ids"));
        $('[data-behaviour="select-single"]').each(function(index) {
          if (selected_ids.indexOf($(this).data("id")) > -1)
            {
              $(this).prop('checked', true);
            }
        });
        if ($('[data-behaviour="select-single"]').length == $('[data-behaviour="select-single"]:checked').length) {
          $('[data-behaviour="select-all"]').prop('checked', true);
        }
        $('.bulk-delete-btn').removeClass('hidden');
      }
      return
    },
    error: function(jqxhr, textStatus, errorThrown) {
      console.log(errorThrown, textStatus);
    }
  });
};

$(document).on('click', '[data-behaviour="next-page-override"]', function() {
  var current_page, thisObj;
  thisObj = $(this);
  current_page = parseInt($('[data-behaviour="current-page"]').data('page'));
  return $(function() {
    return get_override_paginated_list(current_page + 1, thisObj);
  });
});

$(document).on('click', '[data-behaviour="previous-page-override"]', function() {
  var current_page, thisObj;
  thisObj = $(this);
  current_page = parseInt($('[data-behaviour="current-page"]').data('page'));
  return $(function() {
    return get_override_paginated_list(current_page - 1, thisObj);
  });
});

$(document).on('keyup', '[data-type="input-filter"]', function() {
  var thisObj;
  thisObj = $(this);
  return $(function() {
    return get_override_paginated_list(1, thisObj);
  });
});

$(document).on('change', '[data-type="select-filter"]', function() {
  var thisObj;
  thisObj = $(this);
  localStorage.setItem("delete_ids", '');
  return $(function() {
    return get_override_paginated_list(1, thisObj);
  });
});

$(document).on('click', '[data-type="anchor-filter"]', function() {
  var thisObj;
  thisObj = $(this);
  localStorage.setItem("delete_ids", '');
  return $(function() {
    return get_override_paginated_list(1, thisObj);
  });
});
