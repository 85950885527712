$(document).on 'change', '[data-select="country"]', () ->
  country_id = $(this).val()
  $.ajax '/users/state_list.json',
    type: 'GET'
    data:{
      country_id: country_id,
    }
    success: (data, jqxhr, textStatus) ->
      $('#state').empty()
      $('#state').append(new Option('Select state', ''))
      $(data).each ->
        $('#state').append(new Option($(this)[0].name, $(this)[0].id))
