current_request = null
filter_chart = () ->
  broadcast_period = $('#broadcast_period').val()
  contact_period = $('#contact_period').val()
  current_request = $.ajax '/dashboards',
    type: 'GET'
    data: {
      broadcast_period: broadcast_period,
      contact_period: contact_period
    }
    beforeSend: ->
      if current_request != null
        current_request.abort()
      return
    success: (data, jqxhr, textStatus) ->
      $.when($('.chart-section').html data).then ->
        $('.select2').select2()

$(document).on 'change', '[data-select="period"]', () ->
  $ -> filter_chart()
