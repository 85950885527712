$(document).on 'click', '#segment_form_submit', (e) ->
  e.preventDefault()
  $('.error_color').addClass('hidden')
  count = 0
  
  $('.segment_input_validation').each ->
    if $(this).val() == ''
      count++
      $(this).parent().siblings().first().removeClass('hidden')
  if $(this).data('form') != 'modal-form' && $('[data-behaviour="select-single"]:checked').length == 0
    $('.table_validation').removeClass('hidden')
    count++
  if count == 0
    current_request = $.ajax '/segments/check_name_uniqueness.html',
      type: 'GET'
      data: {
        name: $('#segment_name').val(),
        segment_id: $('#segment_name').data('id')
        template: false
      }
      datatype: 'text'
      success: () ->
        delete_ids = localStorage.getItem("delete_ids")
        if delete_ids != null && delete_ids != 'undefined'
          ids = JSON.parse(delete_ids)
          $('#segment_contact_ids').val(ids)
        $('#segment_form_submit').prop('disabled', true)
        $('#segment_form_submit').addClass('disabled')
        $('#segment_form_submit').closest('form').submit()
      error: (response) ->
        if JSON.parse(response.responseText).duplicate_name == true
          $('.duplicate_name_validation').removeClass('hidden')
          count++

# make entire row clickable
$(document).on 'click', '.segment-contact-row', () ->
  $(this).siblings().first().find('[data-behaviour="select-single"]').trigger("click")

$(document).on 'turbolinks:load', () ->
  if $('#segment_form_submit').length != 0
    ids = $('#segment_form_submit').data('ids')
    localStorage.setItem("delete_ids", JSON.stringify(ids))