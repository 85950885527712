filter_link_presence = () ->
  $('[data-behaviour="clear-filters"]').addClass('hidden')
  $('[data-behaviour="filter"]').each ->
    if $(this).val() != ""
      $("[data-behaviour='clear-filters']").removeClass('hidden')

current_request = null

$(document).on "turbolinks:load", () ->
  $('.select2').select2()
  $('*[data-link]').on 'click', ->
    Turbolinks.visit($(this).data('link'))

  $('.datepicker').datepicker({
    format: 'dd-mm-yyyy',
    todayHighlight: true,
    autoclose: true,
  }).attr('readonly', true)
  $('.date-range-picker').attr('readonly', true)
  localStorage.removeItem("delete_ids")
  localStorage.removeItem("selected_count")



$(document).on 'change keypress keyup keydown paste cut', '[data-behaviour="filter"]', (event) ->
  $ -> filter_link_presence()

$(document).on 'click', '.select2', () ->
  $(".select2-search--dropdown").each ->
    $(this).addClass('cm-blue-border')
    unless $(this).hasClass("d-flex")
      $(this).addClass("d-flex align-items-center").append('<span class="icon-search font-size-14"></span>')


$(document).on 'click', '[data-filter="search"]', (e) ->
  e.preventDefault()
  $('[data-field="search"]').removeClass('hidden').focus()
  $('[data-remove="search"]').removeClass('hidden')
  $('.search-filter-container').addClass('search-field-line')
  e.stopPropagation()

$(document).click (event) ->
  unless event.target.closest('[data-field="search"], [data-remove="search"]')
    $('[data-field="search"]').addClass('hidden')
    $('[data-remove="search"]').addClass('hidden')
    $('.search-filter-container').removeClass('search-field-line')

$(document).on 'click', '[data-remove="search"]', () ->
  $('[data-field="search"]').val('').focus().keyup()

$(document).on 'click', '.select2', () ->
  $('.select2-search__field').attr('placeholder', 'Search').addClass('placeholder-style')


window.open_show_modal = () ->
  $('.cm-panel-wrap').css('transform', 'translateX(0%)')
  $('.cm-panel-backdrop').css('transform', 'translateX(0%)')

$(document).on 'keydown', 'input[type=number]', (e) ->
  if !((e.keyCode > 95 and e.keyCode < 106) or (e.keyCode > 47 and e.keyCode < 58) or e.keyCode == 8 or e.keyCode == 110 or e.keyCode == 190)
    e.preventDefault()

$(document).on 'click', '.dropdown-drp-icon-container', () ->
  $(this).siblings('.date-range-picker').click()

$(document).on 'change', '.daterangepicker-input', () ->
  $(this).find('.date-range-picker').removeClass('drp-active')
  $(this).find('.date-range-picker-2').removeClass('drp-active')
  $(this).width('190px')

$(document).on 'keyup', 'a, button, .select2-selection, input[type=submit], input[type=text]', () ->
  $(this).addClass('focusOutline').blur ->
    $(this).removeClass 'focusOutline'


capitalizeFirstLetter = (string) ->
  return string.charAt(0).toUpperCase() + string.slice(1);

# ----------------- generic bulk delete function --------------- #
$(document).on 'click', '[data-behaviour="select-all"]', () ->
  current_page_records_delete_ids = $('#record_delete_ids').data('record-delete-ids') # ids of current page records

  if localStorage.getItem("delete_ids") == "" || localStorage.getItem("delete_ids") == null
    selected_ids = []
  else
    selected_ids = JSON.parse(localStorage.getItem("delete_ids"))

  if $('[data-behaviour="select-all"]:checked').length > 0
    $('[data-behaviour="select-single"]').prop('checked', true)

    for item, i in current_page_records_delete_ids
      if selected_ids.indexOf(item) == -1
        selected_ids.push(item);     # inserting current_page_records_delete_ids array individually. If id already present then avoid push

    localStorage.setItem("delete_ids", JSON.stringify(selected_ids))
  else
    $('[data-behaviour="select-single"]').prop('checked', false)
    selected_ids = selected_ids.filter((i) => (current_page_records_delete_ids.indexOf(i) == -1)) # removing ids of current page
    localStorage.setItem("delete_ids", JSON.stringify(selected_ids))
  $('.selected-items-count').text("#{selected_ids.length} selected")
  localStorage.setItem('selected_count', selected_ids.length)
  show_hide_bulk_delete_btn(selected_ids.length)

show_hide_bulk_delete_btn = (selected_count) ->
  if selected_count > 1
    $('.bulk-delete-btn').removeClass('hidden')
  else
    $('.bulk-delete-btn').addClass('hidden')

$(document).on 'click', '[data-behaviour="select-single"]', () ->
  if $('#record_delete_ids').data('record-delete-ids').length == $('[data-behaviour="select-single"]:checked').length
    $('[data-behaviour="select-all"]').prop('checked', true)
  else
    $('[data-behaviour="select-all"]').prop('checked', false)
  if localStorage.getItem("delete_ids") == "" || localStorage.getItem("delete_ids") == null
    selected_ids = []
  else
    selected_ids = JSON.parse(localStorage.getItem("delete_ids"))
  index_of_element = selected_ids.indexOf($(this).data('id'))
  if $(this).prop('checked') && index_of_element == -1
    selected_ids.push($(this).data('id'))
    localStorage.setItem("delete_ids", JSON.stringify(selected_ids))
  else if $(this).prop('checked') == false && index_of_element > -1
    if index_of_element == 0 && selected_ids.length == 1
      selected_ids.splice(0, 1)
      localStorage.setItem("delete_ids", '')
      $('.bulk-delete-btn').addClass('hidden')

    else
      selected_ids.splice(index_of_element, 1)
      localStorage.setItem("delete_ids", JSON.stringify(selected_ids))

  localStorage.setItem('selected_count', selected_ids.length)
  $('.selected-items-count').text(localStorage.getItem('selected_count') + ' selected')
  show_hide_bulk_delete_btn(selected_ids.length)


$(document).on 'click', '[data-behaviour="bulk-delete"]', () ->
  controller_name = $(this).data('controller')
  selected_ids = JSON.parse(localStorage.getItem("delete_ids"))
  url = "#{controller_name}/bulk_delete"
  if confirm("Are you sure you want to delete #{selected_ids.length} record(s)?")
    $(this).prop('disabled', true).addClass('opacity-60').text('Loading ...').prepend("<i class='fa fa-spinner fa-spin mr-2'></i>")

    $.ajax url,
      type: 'DELETE'
      data: {
        ids: selected_ids
      }
      dataType: 'text'
      success: (data, jqxhr, textStatus) ->
        window.location.replace(location.origin + '/' +controller_name)
      error: (xhr) ->
        console.log(xhr.status)


# ---------- generic functions for bulk upload -------------  #
$(document).on 'click', '[data-behaviour="upload-bulk-file"]', (e) ->
  data_model= $(this).data("model")
  $(".#{data_model}-bulk-file-input").trigger('click')

$(document).on 'change', '.bulk-input', (e) ->
  data_behaviour = $(this).data("behaviour")
  console.log("[data-behaviour='#{data_behaviour}']")
  $("[data-behaviour='#{data_behaviour}']").trigger('click')

# ---------- bootstrap notice/alert slide-up function -------------  #
$(document).on 'turbolinks:load', ->
  $(document).ready ->
    setTimeout (->
      $('.alert').slideUp 500
      return
    ), 5000
    return
  return


getSideNavCalulatedHieght = () ->
  sideNavFooterHeight = $('.sidenav-footer-section').height()
  calculatedHeight = "calc(100vh - " + sideNavFooterHeight+"px"+")"
  $('.side-navbar').css("height", calculatedHeight)

$(document).on "turbolinks:load", () ->
  getSideNavCalulatedHieght()

$(document).on 'click', '#compay-toggle', (e) ->
  setTimeout (->
    getSideNavCalulatedHieght()
    return
  ), 500